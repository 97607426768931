// Externe bibliotheken
import {Splide} from '@splidejs/splide';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
import {delegate, followCursor, roundArrow} from 'tippy.js';
import * as noUiSlider from 'nouislider';
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import { Fancybox } from "@fancyapps/ui";

// CSS Imports
import '@splidejs/splide/dist/css/splide-core.min.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward-subtle.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/border.css';
import 'nouislider/dist/nouislider.css';
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// Alpine.js plugins
import AlpineFloatingUI from "@awcodes/alpine-floating-ui";

// Lokale scripts
import swiper from './swiper.js';
import modal from './modal.js';
import maps from './map.js';
import geolocation from "./geolocation";
import recaptcha from "./recaptcha";
import filamentCompanyPage from "./filament-company-page";
import ToastComponent from '../../vendor/usernotnull/tall-toasts/resources/js/tall-toasts'
import {createStack} from "stackid";
import lottie from "lottie-web/build/player/lottie_light";
import googleAdManager from "./googleAdManager.js";

// Statische assets (als je dit nodig hebt)
import.meta.glob([
    '../images/**',
]);

Fancybox.bind('[data-fancybox]');

// Initialiseren van plugins en configureren van globale objecten
window.stack = createStack();

Alpine.plugin(modal);
Alpine.plugin(swiper);
Alpine.plugin(filamentCompanyPage);
Alpine.plugin(maps);
Alpine.plugin(AlpineFloatingUI);
Alpine.plugin(geolocation);
Alpine.plugin(recaptcha);
Alpine.plugin(ToastComponent);
Alpine.plugin(googleAdManager);

delegate('body', {
    interactive: true,
    allowHTML: true,
    trigger: 'click',
    theme: 'nieuwbouw',
    animation: 'shift-toward-subtle',
    target: '[data-tippy-content]',
    arrow: true,
    interactiveBorder: 30,
    maxWidth: 320,
    followCursor: false,
    plugins: [followCursor],
});

document.querySelectorAll('.overflow-draggable').forEach(el => {
    const handlePointerEvent = (ev) => {
        if (ev.type === "pointerdown") el.setPointerCapture(ev.pointerId);
        else if (ev.type === "pointerup") el.releasePointerCapture(ev.pointerId);
        else if (ev.type === "pointermove" && el.hasPointerCapture(ev.pointerId)) el.scrollLeft -= ev.movementX;
    };

    el.addEventListener("pointerdown", handlePointerEvent);
    el.addEventListener("pointerup", handlePointerEvent);
    el.addEventListener("pointermove", handlePointerEvent);
})


// Stel Alpine beschikbaar op window
window.Alpine = Alpine;

Alpine.data('autocomplete', () => ({
    focus: -1,
    selected: false,
    search: '',

    init() {
        this.search = this.$wire.get('search');

        this.$watch('search', () => {
            this.$wire.set('search', this.search)
        })
    },

    setSearch() {
        this.search = this.$wire.get('search');
    },

    setFocus(f) {
        this.focus = f;
    },

    onClick(e) {
        if (!this.$wire.selected && (this.focus >= 0)) {
            e.preventDefault();
            this.$wire.call('select', this.$refs[`item-${this.focus}`]?.dataset.ulid);
            this.focus = -1;
        }
    },

    onBackspace() {
        if (this.$wire.selected) {
            this.search = '';
        }

    },

    onOutside() {
        this.$wire.call('close')
    },

    scrollTo(idx) {
        this.$refs[`item-${idx}`]?.scrollIntoView(false);
    },

    focusNext() {
        const nextIndex = this.focus + 1;
        const total = this.$refs.suggestions?.childElementCount ?? 0;

        if (nextIndex < total) {
            this.setFocus(nextIndex);
            this.scrollTo(nextIndex);
        }
    },

    focusPrev() {
        const nextIndex = this.focus - 1;

        if (nextIndex >= 0) {
            this.setFocus(nextIndex);
            this.scrollTo(nextIndex);
        }
    },
}));

Alpine.data('share', () => ({
    open: false,

    shareApp(app) {
        if (typeof app !== 'string' || String(app).trim().length === 0) {
            return;
        }

        if (['facebook', 'whatsapp'].indexOf(app) === -1) {
            console.warn(`Sharing this page via "${app}" is not yet supported.`);
            return;
        }

        let encodedUrl = encodeURIComponent(window.location.href);
        let shareUrl;

        if (app === 'facebook') {
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        } else if (app === 'whatsapp') {
            shareUrl = `https://api.whatsapp.com/send?phone=&text=${encodedUrl}`;
        }

        // Add campaign for tracking inbound traffic
        shareUrl += encodeURIComponent(`&utm_medium=social&utm_campaign=sharelinks&utm_source=${app}`);

        window.open(shareUrl, '_blank');
    },

    async copyLink() {
        try {
            await navigator.clipboard.writeText(window.location.href);

            Toast.success('De link is succesvol gekopieerd!');
        } catch (err) {
            console.error(`Failed to copy to clipboard: ${window.location.href}`);
        }
    }
}));

Alpine.data('slideoutBar', () => ({
    isVisible: false,
    thresholdTop: 100,

    init() {
        this.updateVisibility();
        window.addEventListener('scroll', () => {
            this.updateVisibility();
        });
    },

    updateVisibility() {
        const screenWidth = window.innerWidth;
        const scrolledDown = window.scrollY > this.thresholdTop;

        if (screenWidth > 992) {
            this.isVisible = scrolledDown;
        } else {
            const footer = document.querySelector('body > footer');
            const footerTop = footer ? footer.getBoundingClientRect().top : 0;
            const footerInView = footerTop < window.innerHeight;

            this.isVisible = scrolledDown && !footerInView;
        }
    }
}))

Alpine.data('continuousSlider', () => ({
    init() {
        new Splide(this.$el, {
            type: 'loop',
            autoWidth: true,
            pagination: false,
            arrows: false,
        }).mount({AutoScroll});
    }
}))

Alpine.data('loadingScreen', (duration = 10000, max = 100, removeAtEnd = false) => ({
    isVisible: false,
    progress: 0,
    duration: parseInt(duration), // Convert duration to integer
    max: parseInt(max), // Maximum progress value
    removeAtEnd: removeAtEnd, // Maximum progress value

    init() {
        window.addEventListener('start-loading-screen', () => {
            this.startLoading();
        });

        window.addEventListener('stop-loading-screen', () => {
            this.stopLoading();
        });
    },
    startLoading() {
        this.isVisible = true;
        this.progress = 0;
        this.animateProgress();
    },
    stopLoading() {
        this.isVisible = false;
        this.progress = 0;
    },
    animateProgress() {
        let interval = 100; // Update interval in ms
        let increment = (interval / this.duration) * this.max;

        let intervalId = setInterval(() => {
            this.progress += increment;
            if (this.progress >= this.max) {
                clearInterval(intervalId);

                if (this.removeAtEnd) {
                    this.stopLoading();
                }
            }
        }, interval);
    }
}))

Alpine.data('wizardInputButtons', ({options, fieldName, initialValue}) => ({
    selectedOption: initialValue || null,

    clickInput(value) {
        this.selectedOption = value;

        this.$wire.$set(fieldName, value);
        this.$wire.dispatch('nextMortgageStep');
    },

    navigateOptions(direction, event) {
        const currentIndex = options.indexOf(this.selectedOption);
        if (direction === 'next') {
            this.selectedOption = options[(currentIndex + 1) % options.length];
        } else if (direction === 'prev') {
            this.selectedOption = options[(currentIndex - 1 + options.length) % options.length];
        }
        this.$wire.$set(fieldName, this.selectedOption);

        event.target.closest('form').querySelector(`[value="${this.selectedOption}"]`).focus();
        event.target.closest('form').querySelector(`[value="${this.selectedOption}"]`).checked = true;
    },

    submitSelection() {
        this.$wire.$set(fieldName, this.selectedOption);
        this.$wire.dispatch('nextMortgageStep');
    }
}));

Alpine.data('lottieWeb', ({filename, autoplay = false, loop = false}) => ({
    instance: null,
    isLoaded: false,

    filename,
    autoplay,
    loop,

    init() {
        this.loadLottieJson()
            .then(data => {
                this.loadLottieAnimation(data);
            });
    },
    async loadLottieJson() {
        try {
            const res = await fetch(`/animations/${this.filename}`);
            return await res.json();
        } catch (error) {
            console.error('Failed to load animation JSON:', error);
        }
    },
    loadLottieAnimation(animationData) {
        this.instance = lottie.loadAnimation({
            name: 'mortgage',
            container: this.$refs.lottieContainer,
            renderer: 'svg',
            loop: this.loop,
            autoplay: this.autoplay,
            animationData: animationData
        });

        this.isLoaded = true;
    },
    playAnimation() {
        if (this.isLoaded) {
            setTimeout(() => {
                lottie.play(this.instance.name)
            }, 300)
        } else {
            setTimeout(this.playAnimation.bind(this), 100); // Retry after a short delay
        }
    }
}));

Alpine.data('searchDropdown', ($wire) => ({
    showDropdown: $wire.$entangle('showDropdown', true),
    modal: $wire.$entangle('showModal', true),
    focused: -1,

    init() {
        // set onclick for modal button
        if(document.getElementById('searchSuggestionsModalButton')) {
            document.getElementById('searchSuggestionsModalButton').onclick = () => {
                new Promise(resolve => {
                    this.modal = true;
                    resolve();
                }).then(() => {
                    document.getElementById("searchSuggestionsModalInput").focus();
                });
            }
        }

        // alleen bij dropdown, modal is niet nodig om radius te hiden
        this.$watch('showDropdown', (value) => {
            if (value) {
                window.dispatchEvent(new CustomEvent('focus-search'));
            } else {
                window.dispatchEvent(new CustomEvent('blur-search'));
            }
        });
    },

    focusNext() {
        const totalItems = this.getItems().length;
        if (totalItems === 0) return;

        this.focused = (this.focused + 1) % totalItems;
        this.updateFocus();
    },

    focusPrev() {
        const totalItems = this.getItems().length;
        if (totalItems === 0) return;

        this.focused = (this.focused - 1 + totalItems) % totalItems;
        this.updateFocus();
    },

    setFocus(index) {
        this.focused = index;
        this.updateFocus();
    },

    getItems() {
        let items = [];
        if (this.showDropdown) {
            items = this.$refs.suggestionListDropdown.querySelectorAll('li')
        } else if (this.modal) {
            // zit in modal dus kom er niet bij
            items = this.$el.querySelectorAll('ul[x-ref="suggestionListModal"] li')
        }
        return items;
    },

    updateFocus() {
        const items = this.getItems();
        items.forEach((item, index) => {
            if (index === this.focused) {
                item.focus();
                item.scrollIntoView({behavior: 'smooth', block: 'nearest'});
            }
        });
    },

}))

Alpine.data('editableLabel', ($wire, params = []) => ({
    editable: false,
    params,
    originalValue: '',
    editableLabel: null,

    init() {
        this.editableLabel = this.$refs.editableLabel;

        this.$watch('editable', value => {
            this.editableLabel.setAttribute("contenteditable", value);

            if (value) {
                this.originalValue = this.editableLabel.innerText;
                this.editableLabel.focus();
                this.setSelection();
            }
        });
    },

    setSelection(){
        let sel = window.getSelection();
        sel.selectAllChildren(this.editableLabel);
        sel.collapseToEnd();
    },

    handleClickOutside(event) {
        if (this.editable) {
            this.revertChanges();
        }
    },

    saveChanges() {
        const newValue = this.editableLabel.innerText.trim();
        if (newValue && newValue !== this.originalValue) {
            $wire.call('updateLabel', {"label": newValue, ...this.params});
        } else {
            this.revertChanges();
        }
        this.editable = false;
    },

    revertChanges() {
        this.editableLabel.innerText = this.originalValue;
        this.editable = false;
    }
}));

Alpine.data('rangeSlider', ($wire, params = {}) => ({
    values: params.values ?? [],
    selected: params.selected ?? [0, -1],

    rangeFrom: $wire.$entangle(params.rangeFromModel, true),
    rangeTo: $wire.$entangle(params.rangeToModel, true),

    sliderInstance: null,

    init() {
        const values = this.values;

        let format = {
            to: function(value) {
                return values[Math.round(value)];
            },
            from: function (value) {
                return values.indexOf(Number(value));
            }
        };

        // Sla het element-referentie (div) van de slider op
        this.sliderInstance = this.$refs.slider;

        // Maak de noUiSlider aan
        noUiSlider.create(this.sliderInstance, {
            animate: false,
            keyboardSupport: true,
            start: this.selected,
            connect: true,
            margin: 1,
            step: 1,
            range: {
                min: 0,
                max: this.values.length - 1
            },
            tooltips: {
                to: function(value) {
                    if(value === (values.length - 1)) {
                        return 'Geen maximum';
                    }

                    return new Intl.NumberFormat('nl-NL', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).format(
                        values[Math.round(value)],
                    );
                },
            },
            format: format
        });

        // on update range slider
        let debounceTimer = null;
        this.sliderInstance.noUiSlider.on('update', (sliderValues, handle) => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                const val = parseFloat(sliderValues[handle]);
                if (handle === 1) {
                    this.$refs.rangeTo.value = val > 0 ? val : '';
                    this.$refs.rangeTo.dispatchEvent(new Event('change', { bubbles: true }));
                } else {
                    this.$refs.rangeFrom.value = val > 0 ? val : '';
                    this.$refs.rangeFrom.dispatchEvent(new Event('change', { bubbles: true }));
                }

                this.checkValueSelected();
            }, 100); // of de debounce-duur die je wilt
        });

        this.$watch('rangeFrom', (newVal) => {
            const fromValue = newVal || 0; // fallback naar 0
            const toValue   = parseFloat(this.sliderInstance.noUiSlider.get()[1]);

            this.sliderInstance.noUiSlider.set([fromValue, toValue]);
        });

        this.$watch('rangeTo', (newVal) => {
            // Livewire kan '' sturen als "Geen maximum"
            let toValue = newVal || -1; // fallback naar -1
            const fromValue = parseFloat(this.sliderInstance.noUiSlider.get()[0]);
            this.sliderInstance.noUiSlider.set([fromValue, toValue]);
        });

        this.checkValueSelected();
    },

    checkValueSelected() {
        this.$refs.slider.classList.remove('noUi-hasActive');

        // Lees de huidige (échte) waarden van slider
        const [currentMin, currentMax] = this.sliderInstance.noUiSlider.get().map(Number);

        // Als we niet op 0 of niet op -1 staan, is 'ie actief
        if (!(currentMin === 0) || !(currentMax === -1)) {
            this.$refs.slider.classList.add('noUi-hasActive');
        }
    }
}));

Alpine.data('counterInput', ($wire, params = {}) => ({
    count: params.toEntangle ? $wire.$get(params.toEntangle) : params.default || 0,
    min: params.min || 0,
    max: params.max || 20,
    placeholder: params.placeholder || '0',

    get displayValue() {
        return this.count ?? this.placeholder;
    },

    init() {
        this.validateCount();

        this.$watch('count', Alpine.debounce(() => {
            if (params.toEntangle) {
                $wire.$set(params.toEntangle, this.count);
            }
        }, 500));
    },

    increment() {
        if (this.count < this.max) {
            this.count++;
        }
    },

    decrement() {
        if (this.count > this.min) {
            this.count--;
        }
    },

    validateCount() {
        if (this.count < this.min) {
            this.count = this.min;
        } else if (this.count > this.max) {
            this.count = this.max;
        }

        if(parseInt(this.count) === 0) {
            this.count = null
        }
    }
}));


Livewire.start()
